import { environment } from '~/constantDefinitions';
import * as Sentry from '@sentry/react';
import { fullStoryIntegration } from '@sentry/fullstory';
import { FullStory } from '@fullstory/browser';
import { isNotNil } from '~/utilities/type-guards';
import { DatadogData, AnonymousMetricsData } from './getDatadogData';
import { isWistiaError } from './WistiaError';

type BeforeSendType = Parameters<typeof Sentry.init>[0]['beforeSend'];

const ignoredHosts = ['https://pipedream.wistia.com', 'https://distillery.wistia.com'];
const ignoredErrors = [
  'Object.hasOwn is not a function', // This error happens in older versions of safari that we don't support
  'The play() request was interrupted by a call to pause().', // Error on Chrome browsers that we don't care about
];
const sentryDsn =
  'https://4911a68ba67b14ac99dfe79bf0e7ba6e@o4505518331658240.ingest.us.sentry.io/4505635365912576';
const sentryTunnelEndpointPath = '/_sentry/tunnel';

const beforeSend: BeforeSendType = (event, hint) => {
  if (
    [...ignoredHosts, ...ignoredErrors].some((host) => {
      return isNotNil(event.message) && event.message.includes(host);
    })
  ) {
    return null;
  }

  if (isNotNil(hint.originalException) && isWistiaError(hint.originalException)) {
    const tags = event.tags ?? {};

    tags.product = hint.originalException.product;
    tags.pillar = hint.originalException.pillar;
    event.tags = tags;
  }

  return event;
};

export const initializeSentryClient = ({
  displayName,
  email,
  accountKey,
  version,
}: DatadogData): void => {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [fullStoryIntegration('wistia', { client: FullStory })],
    // Session Replay
    replaysSessionSampleRate: 0, // We're using fullstory. We don't need replays.
    replaysOnErrorSampleRate: 0, // We're using fullstory. We don't need replays.
    environment,
    release: version,
    tunnel: sentryTunnelEndpointPath,
    beforeSend,
  });

  Sentry.setUser({
    username: displayName,
    email,
    segment: accountKey,
    ip_address: '{{auto}}', // eslint-disable-line camelcase
  });
};

export const initializeAnonymousSentryClient = ({ version }: AnonymousMetricsData): void => {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.1, // capture 10% of users for performance data
    sampleRate: 1, // capture 100% of all errors
    replaysSessionSampleRate: 0, // never capture replays
    replaysOnErrorSampleRate: 0, // never capture replays
    environment,
    release: version,
    tunnel: sentryTunnelEndpointPath,
    beforeSend,
  });

  Sentry.setUser({
    username: 'anonymous-user',
    segment: 'anonymous-user-segment',
    ip_address: '{{auto}}', // eslint-disable-line camelcase
  });
};
