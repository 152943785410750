import { datadogRum, RumErrorEvent } from '@datadog/browser-rum';
import { environment } from '~/constantDefinitions';
import { setHostnameSansAccountKey } from './setHostnameSansAccountKey';
import { DatadogData } from './getDatadogData';

const shouldDiscardError = (errorEvent: RumErrorEvent) => {
  const { error } = errorEvent;
  if (error.message.includes('Object.hasOwn is not a function')) {
    // This error happens in older versions of safari that we don't support
    return true;
  }

  const ignoredHosts = ['https://pipedream.wistia.com', 'https://distillery.wistia.com'];
  const ignoredMessages = ['The play() request was interrupted by a call to pause().'];
  const itemsToIgnore = [...ignoredHosts, ...ignoredMessages];

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < itemsToIgnore.length; i++) {
    if (error.message.includes(itemsToIgnore[i])) {
      return true;
    }
  }

  return false;
};

export const initializeDataDogClient = ({
  displayName,
  email,
  accountName,
  accountKey,
  plan,
  createdAt,
  version,
}: DatadogData): void => {
  datadogRum.init({
    clientToken: 'pub955cba5cf2f05f51704fc46401c4c6e0',
    applicationId: '12748e3e-7bc8-4864-9c1b-cfc9896e2e42',
    site: 'datadoghq.com',
    service: 'vma-rum',
    version,
    env: environment as string,
    sessionSampleRate: 50,
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    defaultPrivacyLevel: 'mask-user-input',
    excludedActivityUrls: [
      'https://events.fivetran.com/snowplow/constriction_thought/com.snowplowanalytics.snowplow/tp2',
    ],
    beforeSend(event) {
      // eslint-disable-next-line sonarjs/prefer-single-boolean-return
      if (event.type === 'error' && shouldDiscardError(event)) {
        return false;
      }

      return true;
    },
  });

  datadogRum.setUser({
    displayName,
    email,
    accountName,
    accountKey,
    plan,
    createdAt,
  });

  setHostnameSansAccountKey();
};
